import { ParsedUrlQuery } from 'querystring';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import Head from 'next/head';
import { useStoryblokState, StoryblokComponent } from '@storyblok/react';
import type {
    GetStaticProps,
    GetStaticPaths,
    GetStaticPathsResult,
} from 'next';

import PageNotFound from '@/components/NotFound';
import appConfig from '@/config';
import { isProductionDeployment, ORIGIN } from '@/utils/environment';
import { fetchStoryblokLinks } from '@/utils/request/StoryblokLinks/fetchStoryblokLinks';
import {
    fetchPageItem,
    generatePageQueryKey,
    usePageItem,
} from '@/hooks/pages/usePageItem';

type PageProps = {
    slug: string;
    locale: string;
    preview: boolean;
};

export default function Page({ slug, locale, preview }: PageProps) {
    const { data: storyData } = usePageItem({
        id: slug,
        locale,
        preview,
    });

    const story = useStoryblokState(storyData || null);

    const metaTitle = story?.content?.title
        ? `${story?.content?.title.trim()} | ${appConfig.appName}`
        : appConfig.appName;
    const metaDescription = story?.content?.intro
        ? story.content.intro.trim()
        : appConfig.appDescription;
    const canonicalUrl = story?.full_slug
        ? `${ORIGIN}/${story.full_slug}`
        : ORIGIN;

    return (
        <>
            <Head>
                <title>{metaTitle}</title>
                <meta name="title" property="title" content={metaTitle} />
                <meta name="og:title" property="og:title" content={metaTitle} />
                <meta name="twitter:title" content={metaTitle} />
                {metaDescription && (
                    <>
                        <meta
                            name="description"
                            property="description"
                            content={metaDescription}
                        />
                        <meta
                            name="og:description"
                            property="og:description"
                            content={metaDescription}
                        />
                        <meta
                            name="twitter:description"
                            property="twitter:description"
                            content={metaDescription}
                        />
                    </>
                )}
                {canonicalUrl && (
                    <>
                        <meta
                            name="og:url"
                            property="og:url"
                            content={canonicalUrl}
                        />
                        <meta
                            name="twitter:url"
                            property="twitter:url"
                            content={canonicalUrl}
                        />
                        <link rel="canonical" href={canonicalUrl} />
                    </>
                )}
                {story?.content?.image && (
                    <>
                        <meta
                            name="og:image"
                            property="og:image"
                            content={story.content.image}
                        />
                        <meta
                            name="twitter:image"
                            property="twitter:image"
                            content={story.content.image}
                        />
                    </>
                )}
            </Head>

            {story?.content ? (
                <StoryblokComponent blok={story.content} key={story.id} />
            ) : (
                <PageNotFound />
            )}
        </>
    );
}

interface PageStaticParams extends ParsedUrlQuery {
    slug: string[];
}

export const getStaticProps: GetStaticProps<
    PageProps,
    PageStaticParams
> = async ({
    params,
    preview = !isProductionDeployment,
    locale,
    defaultLocale,
}) => {
    const queryClient = new QueryClient();
    // Set default slug route to home for defaultLocale
    let slug = params?.slug ? params.slug.join('/') : 'home';
    const isDefaultLocale = locale === defaultLocale;

    // If not in defaultLocale
    // prefix locale to requested slug
    // default to locale home page
    if (!isDefaultLocale) {
        slug = params?.slug
            ? `${locale}/${params.slug.join('/')}`
            : `${locale}/`;
    }

    await queryClient.prefetchQuery(
        generatePageQueryKey({
            id: slug,
            locale: locale || (defaultLocale as string),
        }),
        () =>
            fetchPageItem({
                preview,
                id: slug,
            })
    );

    return {
        props: {
            dehydratedState: dehydrate(queryClient),
            slug,
            locale: locale || (defaultLocale as string),
            preview,
        },
        revalidate: 3600,
    };
};

/**
 * If a page handles its own SSG paths it (or its folder) can be ignored by being added to this list
 * https://nextjs.org/docs/messages/conflicting-ssg-paths
 */
const ignoredPaths = [
    'blog/',
    'help-and-faqs/',
    'jobs-that-matter/',
    'jobs-that-matter-setup/',
    'development/',
];

export const getStaticPaths: GetStaticPaths = async ({
    locales,
    defaultLocale,
}) => {
    const queryClient = new QueryClient();

    const paths: GetStaticPathsResult['paths'] = [];

    for (const locale of locales as string[]) {
        const allLinks = await queryClient.fetchQuery(['links', locale], () =>
            locale !== defaultLocale
                ? fetchStoryblokLinks(locale)
                : fetchStoryblokLinks()
        );

        allLinks.map(({ slug, isFolder, isStartpage }) => {
            const ignoreValues =
                ignoredPaths.find((ignoredPath) =>
                    slug.includes(ignoredPath)
                ) || isFolder;

            if (ignoreValues) {
                return;
            }

            const splitSlug =
                slug === 'home' || isStartpage ? [] : slug.split('/');
            paths.push({ params: { slug: splitSlug }, locale });
        });
    }

    return {
        paths,
        fallback: 'blocking', // ISR enabled
    };
};
