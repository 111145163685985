import { Typography } from '@krakentech/coral';
import { PageNotFound } from '@krakentech/coral-organisms';
import { StyledNotFound } from './index.styled';

import Link from '@/components/Link';
import { INTERNAL_PATHS } from '@/utils/urls';

export default function NotFound() {
    return (
        <StyledNotFound>
            <PageNotFound>
                <Typography>Sorry, we can&apos;t find that page</Typography>
                <Link href={INTERNAL_PATHS.HOME.path}>Back to homepage</Link>
            </PageNotFound>
        </StyledNotFound>
    );
}
